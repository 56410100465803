import { createRouter, createWebHashHistory } from 'vue-router'

import RequirePass from '../views/RequirePass.vue'
import UpdatePrice from '../views/UpdatePrice.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RequirePass
  },
  {
    path: '/update-price',
    name: 'UpdatePrice',
    component: UpdatePrice
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
