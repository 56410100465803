import { createStore } from 'vuex'

export default createStore({
  state: {
    defaultHash: 3096442718, //защита сервис
    hashMatches: false,
    shortPilePrice: null,
    longPilePrice: null,
    dirtyPrice: null,
    smellPrice: null,
    smallCarpets: null,
  },
  getters: {
    getDefaultHash: state => {
      return state.defaultHash
    },
    hashMatches: state => {
      return state.hashMatches
    }, 
    getPrices: state => {
      return {
        shortPilePrice: state.shortPilePrice,
        longPilePrice: state.longPilePrice,
        dirtyPrice: state.dirtyPrice,
        smellPrice: state.smellPrice,
        smallCarpets: state.smallCarpets,
      }
    },

  },
  mutations: {
    hashMatches(state, payload) {
      state.hashMatches = payload.value
    },
    setPrices(state, payload) {
      state.shortPilePrice = payload.shortPilePrice;
      state.longPilePrice = payload.longPilePrice;
      state.dirtyPrice = payload.dirtyPrice;
      state.smellPrice = payload.smellPrice;
      state.smallCarpets = payload.smallCarpets;
    }, 
    setSinglePrice(state, payload) {
      state[payload.type] = payload.value;
    }
  },
  actions: {
    hashMatches(context, payload) {
      context.commit('hashMatches', {
        value: payload
      })
    }, 
    setPrices(context, payload) {
      context.commit('setPrices', payload)
    },
    setPrice(context, payload) {
      context.commit('setPrices', payload)
    },
    setSinglePrice(context, payload) {
      context.commit('setSinglePrice', payload)
    }
  },
  modules: {
  }
})
