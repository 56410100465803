<template>
  <div class="container">
    <div class="form-wrapper">
      <div class="form-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormWrapper",
};
</script>

<style lang="scss">
.form-wrapper {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  .form-content {
    padding: 50px;

    -webkit-box-shadow: 0px 0px 22px -5px rgba(232, 232, 232, 1);
    -moz-box-shadow: 0px 0px 22px -5px rgba(232, 232, 232, 1);
    box-shadow: 0px 0px 22px -5px rgba(232, 232, 232, 1);
  }

  .price__input {
    margin-bottom: 20px;
  }
}
</style>
