import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
    if (to.fullPath === "/update-price") {
        if (store.getters.hashMatches) {
            next();
        }
        else {
            router.push("/")
        }
    } else {
        next();
    }   
})

createApp(App).use(store).use(router).mount('#app')
