<template>
  <FormWrapper class="font-wrapper">
    <div class="row">
      <div class="col-12">
        <label for="shortPilePrice" class="price__label">Короткий ворс</label>
        <input
          type="text"
          id="shortPilePrice"
          class="price__input"
          v-model="shortPilePrice"
        />
      </div>
      <div class="col-12">
        <label for="longPilePrice" class="price__label">Длинный ворс</label>
        <input
          type="text"
          id="longPilePrice"
          class="price__input"
          v-model="longPilePrice"
        />
      </div>
      <div class="col-12">
        <label for="dirtyPrice" class="price__label">Сильное загрязнение</label>
        <input
          type="text"
          id="dirtyPrice"
          class="price__input"
          v-model="dirtyPrice"
        />
      </div>
      <div class="col-12">
        <label for="smellPrice" class="price__label">Выведение запаха</label>
        <input
          type="text"
          id="smellPrice"
          class="price__input"
          v-model="smellPrice"
        />
      </div>
      <div class="col-12">
        <label for="smallCarpets" class="price__label">Ковры менее 6м2</label>
        <input
          type="text"
          id="smallCarpets"
          class="price__input"
          v-model="smallCarpets"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12"></div>
      <div class="col-12">
        <button class="price__button" @click="saveValues">Сохранить</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="message" v-if="message">{{ this.message }}</p>
      </div>
    </div>
  </FormWrapper>
</template>

<script>
import FormWrapper from "../components/FormWrapper";

const axios = require("axios");

export default {
  name: "UpdatePrice",
  data() {
    return {
      message: null,
    };
  },
  computed: {
    shortPilePrice: {
      get() {
        const allPrices = this.$store.getters.getPrices;
        return allPrices.shortPilePrice;
      },
      set(value) {
        this.$store.dispatch("setSinglePrice", {
          value: value,
          type: "shortPilePrice",
        });
      },
    },
    longPilePrice: {
      get() {
        const allPrices = this.$store.getters.getPrices;
        return allPrices.longPilePrice;
      },
      set(value) {
        this.$store.dispatch("setSinglePrice", {
          value: value,
          type: "longPilePrice",
        });
      },
    },
    dirtyPrice: {
      get() {
        const allPrices = this.$store.getters.getPrices;
        return allPrices.dirtyPrice;
      },
      set(value) {
        this.$store.dispatch("setSinglePrice", {
          value: value,
          type: "dirtyPrice",
        });
      },
    },
    smellPrice: {
      get() {
        const allPrices = this.$store.getters.getPrices;
        return allPrices.smellPrice;
      },
      set(value) {
        this.$store.dispatch("setSinglePrice", {
          value: value,
          type: "smellPrice",
        });
      },
    },
    smallCarpets: {
      get() {
        const allPrices = this.$store.getters.getPrices;
        return allPrices.smallCarpets;
      },
      set(value) {
        this.$store.dispatch("setSinglePrice", {
          value: value,
          type: "smallCarpets",
        });
      },
    },
  },
  methods: {
    saveValues: function() {
      const pricesJSON = JSON.stringify({
        shortPilePrice: this.shortPilePrice,
        longPilePrice: this.longPilePrice,
        dirtyPrice: this.dirtyPrice,
        smellPrice: this.smellPrice,
        smallCarpets: this.smallCarpets,
      });

      if (this.$store.getters.hashMatches) {
        this.message = null;

        axios
          .post(
            `https://price-api.zaschita-service.by/update?token=${this.$store.getters.getDefaultHash}`,
            pricesJSON
          )
          .then((response) => {
            if (response.status === 200) {
              this.message = "Успешно";
            } else {
              this.message = "Возникла ошибка";
            }
          });
      } else {
        console.warn("Hash Does Not Match!");
      }
    },
  },
  components: {
    FormWrapper,
  },
};
</script>

<style lang="scss" scoped>
.price__input {
  margin-bottom: 20px;
  margin-top: 5px;
}
.font-wrapper {
  max-width: 350px;
}
.message {
  font-weight: 600;
  padding-top: 20px;
  font-size: 18px;
}
</style>
