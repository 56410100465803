<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.price__ {
  &headline {
    font-size: 24px;
    font-weight: 600;

    margin-bottom: 20px;
  }

  &input {
    padding: 10px;

    border: 1.5px solid #dadada;
    border-radius: 3px;

    text-align: center;

    transition: all 0.5s ease;
    width: 100%;

    &:focus,
    &:active {
      border-color: darken($color: #dadada, $amount: 15);
    }
  }

  &button {
    min-height: 46px;
    padding: 6px 12px;
    width: 100%;

    border-radius: 3px;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    background-color: #00a9e4;
    color: #fff;

    transition: all 0.5s ease;

    &:hover {
      background-color: darken($color: #00a9e4, $amount: 5);
    }
  }

  &label {
    margin-bottom: 10px;
    font-weight: 600;
  }
}
</style>
