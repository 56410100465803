<template>
  <FormWrapper>
    <div class="row">
      <div class="col-12">
        <p class="price__headline">
          Введите пароль
        </p>
        <input
          type="text"
          class="price__input"
          placeholder="*********"
          v-model="password"
        />
        <button class="price__button" @click="generateHash">Войти</button>
      </div>
    </div>
  </FormWrapper>
</template>

<script>
import FormWrapper from "../components/FormWrapper";

const stringHash = require("string-hash");
const axios = require("axios");

export default {
  name: "RequirePass",
  data() {
    return {
      password: "",
    };
  },
  computed: {
    defaultHash: function() {
      return this.$store.getters.getDefaultHash;
    },
  },
  methods: {
    generateHash: function() {
      const passwordHash = stringHash(this.password);

      if (passwordHash === this.defaultHash) {
        this.$store.dispatch("hashMatches", true);
        this.$router.push("/update-price");
        // if $store.hashMatches equals to false, the router will redirect user back to this route
      } else {
        this.$store.dispatch("hashMatches", false);
        // setting this thing to false to simplify consistency management
        // in case user goes back to password route and enters different password
      }
    },
  },
  created() {
    axios.get("https://price-api.zaschita-service.by/get").then((response) => {
      if (response.status === 200) {
        this.$store.dispatch("setPrices", response.data);
      }
    });
  },
  components: {
    FormWrapper,
  },
};
</script>

<style lang="scss" scoped></style>
